<template>
  <vs-divider position="left">
  <div class="vs-list--title customer-label" v-if="customerList.length == 0">โครงการ {{roleInfo != null ? roleInfo.customer_name : ""}}</div>
  <!-- <div class="vs-list--title">โครงการ {{roleInfo != null ? roleInfo.customer_name : ""}}</div> -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" v-if="customerList.length > 0">
    <span class="customer-label">โครงการ {{roleInfo != null ? roleInfo.customer_name : ""}}</span> <span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></span>
    <vs-dropdown-menu v-if="customerList.length > 0">
      <div :key="index" class="customer-item" v-for="(customer, index) in customerList" v-on:click="changeCustomer(customer)">
       <span>{{customer}}</span>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</vs-divider>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
import { db, firebaseApp } from "../../../store/firebaseDb";

Vue.use(VueCookies);

export default {
  name: 'customer-header',
  data() {
    return {
      roleInfo: {},
      customerList: [],
    };
  },
  beforeCreate() {
  },
  props: {
    // navbarColor  : { type: String, default: "#fff", },
    // navMenuItems : { type: Array,  required: true   }
  },
  components: {
  },
  computed: {
  },
  methods: {
    getUserRole(custName, cb) {
      console.log("[[getUserRole]]");

      const userInfo = $cookies.get("userInfo");
      console.log("getUserRole userInfo=", userInfo);
      // Vue.$cookies.remove("customer_list");

      db.collection("users")
      .where("customer_name", "==", custName)
        .get()
        .then((snapshots) => {
          snapshots.forEach((doc) => {
            const user = doc.data();
          console.log("user, roleInfo=", user);
          if ( user === null || user === undefined) {
          this.$vs.notify({
            color: "danger",
            title: "",
            text: "ผู้ใช้งานนี้ไม่มีข้อมูลในระบบ Cloud Management",
          });
            return ;
          }
          user.customer_name = custName;
          Vue.$cookies.set("roleInfo", user);
          Vue.$cookies.set("user_cloud_config", user);

          const roleInfo = $cookies.get("roleInfo");
          console.log(" 111**this.roleInfo", roleInfo);

          const customerName = $cookies.get("customer");
          console.log(">>>customerName=" + customerName);
          cb();
          });

          // if(cb != null)cb()
          // console.log("user ", user);
          // do something with document

        });
    },

    changeCustomer (custName){
      console.log("[[changeCustomer]] custName="+custName);
      // Vue.$cookies.remove("customer");
      Vue.$cookies.set("customer", custName);

      // this.roleInfo = $cookies.get("roleInfo");
      // this.roleInfo.customer_name = custName;
      // Vue.$cookies.set("roleInfo", this.roleInfo);
      this.getUserRole(custName, function () {
              // thisGlo.$router.replace("/")
              window.location.reload();
            });
    }
  },
  created() {
        this.roleInfo = $cookies.get("roleInfo");
        this.customerList = $cookies.get("customer_list");
        if ( this.customerList ) {
          this.customerList = JSON.parse(this.customerList);
        } else {
          this.customerList = [];
        }
         console.log("xxxx this.customerList=",this.customerList);
  }
}
</script>


<style>
  .icon,
.customer-label {
  vertical-align: middle;
  display: inline-block;
}
.customer-item{
  cursor: pointer;
  background-color: #fafafa;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: #e5e5e5 1px solid;
}
.customer-item span{
  font-size: 16px;
  color:#626262;
  width: 400px;
}

.customer-label{
  font-size: 16px;
}
</style>
